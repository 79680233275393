<template>
  <v-container>
    <Chicken class="text-center mx-auto mt-10 pt-10"></Chicken>
        <v-card
          v-if="clickCount > 99"
          class="text-center mx-auto"
          max-width="300px"
          flat
        >
          <div class="text-h4 pt-2">{{clickCount}} Clucks!</div>
        </v-card>
    <v-card flat tile>
      <v-card-text class="text-center">
        Welcome to Cosmic Industries where the cute is made. All designs were created by illustrator Elliot Rahn. 
        <br/>
        Check out our Instagram for product updates and more art, or drop us a line here if you have any questions!
      </v-card-text>
    </v-card>
    <v-sheet>
    <v-snackbar
      v-model="submitted"
      :timeout="7000"
      :value="true"
      absolute
      centered
      color="purple accent-4"
      elevation="24"
    >
      Thanks for the feedback! We will reach out shortly.
    </v-snackbar>
    <v-snackbar
      v-model="error"
      :timeout="7000"
      :value="true"
      absolute
      centered
      color="purple accent-4"
      elevation="24"
    >
      Sorry, there was an error when sending feedback. Please try again later.
    </v-snackbar>
    <v-form v-model="valid" netlify name="contact" netlify-honeypot="bot-field" data-netlify="true" @submit.prevent="handleSubmit">
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="form.firstname"
            name="firstname"
            :rules="nameRules"
            :counter="50"
            label="First name"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="form.lastname"
            name="lastname"
            :rules="nameRules"
            :counter="50"
            label="Last name"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="form.email"
            name="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
        <v-col
        cols="12"
        md="4"
        >
        <v-text-field
          v-model="form.ordernumber"
          name="ordernumber"
          label="Order Number"
        ></v-text-field>
        </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
          cols="12"
          md="8"
          >
          <v-textarea
          v-model="form.comments"
          name="comments"
          label="Comments"
        ></v-textarea>
        </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn
          class="mb-4"
          :disabled="!valid"
          type="submit"
          >Submit</v-btn>
        </v-row>
    </v-container>
  </v-form>
  </v-sheet>
  </v-container>
</template>

<script>
import Chicken from '../components/Chicken.vue';
import axios from "axios";
export default {
  name: 'AboutPage',
  title: 'Cosmic Industries - About',
  components: {
    Chicken,
  },
  data: () => ({
      valid: false,
      submitted: false,
      error: false,
      form: {
        firstname: '',
        lastname: '',
        email: '',
        ordernumber: '',
        comments: ''
      },
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 50 || 'Name must be less than 50 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    }),
    methods: {
      encode (data) {
        return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
      },
      handleSubmit () {
        const axiosConfig = {
          header: { "Content-Type": "application/x-www-form-urlencoded" }
        };
      axios.post(
        "/",
        this.encode({
          "form-name": "contact",
          ...this.form
        }),
        axiosConfig
      ).then(()=> {
        this.submitted = true
        for ( var prop in this.form ) {
          if (Object.prototype.hasOwnProperty.call(this.form, prop)) { delete this.form[prop]; }
        }
      }).catch((error)=> {
        if (error.response) {
          console.log(`Client recieved error.`)
          this.error = true
        } else if (error.request) {
          console.log(`Request never recieved/left client.`)
          this.error = true
        }
      });
    }
  }
};
</script>