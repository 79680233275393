 <template>

 <div class="chikin" @click.prevent="playSound()">
  <audio id="sound" src="@/assets/public_chikin.mp3"></audio>
  <svg xmlns="http://www.w3.org/2000/svg" width="211.2" height="330.279">
  <g class="backwing">
    <path fill="#FFF" d="M130.579 163.212l7.43-23.31 6.119-26.89 8.514-15.618 11.359-13.049 12.078-9.294 14.167-4.731 9.062 1.035 8.003 5.884v12.259l-5.732 7.381-10.666 4-6.334 2.666 11.272 5.655 5.856 7.566-2.791 7.15-7.998 6.537-5.261 2.716-6.411 2.71 12.333 4.025 7.334 4.975 5.38 6-.714 7-7.303 5.333-5.97 1.638-9.363 1.182h-9.817l-7.497 14.053-5.853 9.962-9.863 5.972-5.334-.14-12-2-5.333-17.667z"/>
    <path d="M144.079 126.879c-.3-11.479 6.081-22.362 12.901-31.151 6.844-8.819 15.848-17.341 26.365-21.505 9.049-3.583 25.551-.956 22.577 12.068-2.23 9.769-17.817 13.05-25.605 16.429-1.938.84-1.451 4.227.597 4.569 5.485.918 20.585 3.275 17.928 11.958-2.364 7.728-14.243 12.011-20.928 14.221-2.812.93-2.079 4.595.665 4.911 7.589.874 28.957 7.609 22.467 18.889-2.491 4.33-10.965 5.24-15.313 5.82-5.306.709-10.827.891-16.153.291-.799-.09-1.813.528-2.158 1.238-5.555 11.417-8.522 30.121-24.842 28.762-3.215-.268-3.189 4.734 0 5 18.874 1.572 22.672-17.906 29.158-31.238l-2.158 1.238c7.112.801 14.465.208 21.474-1.132 6.561-1.255 13.521-3.837 15.666-10.714 4.515-14.479-17.849-21.97-28.14-23.154l.665 4.911c9.062-2.997 22.403-8.474 24.768-19.026 2.588-11.552-13.823-15.466-21.768-16.795l.597 4.569c9.737-4.225 26.842-8.234 28.238-21.159 1.511-13.976-11.338-19.012-23.266-17.832-12.952 1.281-24.63 12.629-32.619 21.996-8.556 10.031-16.472 23.212-16.115 36.836.083 3.218 5.083 3.226 4.999 0z"/>
    <path d="M141.579 129.379c3.225 0 3.225-5 0-5s-3.225 5 0 5zM133.579 161.379c3.225 0 3.225-5 0-5-3.224 0-3.224 5 0 5z"/>
    <g>
      <path d="M135.99 159.544c2.181-10.813 5.146-21.354 8-32 .835-3.119-3.988-4.441-4.822-1.33-2.853 10.647-5.818 21.188-8 32-.635 3.148 4.185 4.489 4.822 1.33z"/>
    </g>
  </g>
  <g id="rleg">
    <path fill="#FBA919" d="M81.579 233.629l.392 20.243.578 14.007-2.226 3.308-11.257 12.745v4.197l11.257-3.25 3.723 13.5 8.944-13.5 14.414 5-14.414-23.875v-31.653z"/>
    <path d="M90.079 237.879v30c0 3.225 5 3.225 5 0v-30c0-3.225-5-3.225-5 0zM79.079 241.879c.064 9.676.942 19.323 1 29 .02 3.224 5.02 3.226 5 0-.058-9.677-.936-19.324-1-29-.021-3.224-5.021-3.226-5 0z"/>
    <path d="M81.317 267.721c-8.407 4.562-13.296 12.842-17.897 20.896-1 1.75.679 4.515 2.823 3.673 5.303-2.083 10.697-3.918 16-6l-3.075-1.746c1.62 5.376 1.903 10.757 1.911 16.335.003 2.705 3.24 3.121 4.659 1.262 4.108-5.384 6.892-11.616 11-17l-3.926.506c4.402 3.99 9.663 5.561 15.103 7.644 1.684.645 3.768-1.368 3.075-3.076-3.322-8.19-9.175-14.817-13.252-22.597-1.497-2.856-5.812-.329-4.317 2.523 3.859 7.363 9.608 13.661 12.748 21.403l3.075-3.076c-4.565-1.748-9.192-2.999-12.897-6.356-1.3-1.179-2.927-.804-3.926.506-4.108 5.384-6.892 11.616-11 17l4.659 1.262c-.008-6.025-.342-11.864-2.089-17.665-.361-1.198-1.783-2.253-3.075-1.746-5.303 2.083-10.697 3.918-16 6l2.823 3.673c4.174-7.308 8.459-14.955 16.103-19.104 2.833-1.539.309-5.855-2.525-4.317z"/>
  </g>
  <g id="lleg">
    <path fill="#FBA919" d="M113.829 233.629l3.317 15.197 1.464 14.301-2.39 13.391-3.641 4.361 17 1.426 6.316 4.121 5.684-8.271 2.549-4.739-8.233-2.037 6.315-7-12.631 1.474-4.333-22.724-.667-13.25z"/>
    <path d="M112.168 235.544c1.254 7.316 2.896 14.546 3.632 21.945.709 7.124 1.216 16.69-4.989 21.622-1.78 1.414-.237 4.272 1.768 4.268 4.447-.011 9.065-.374 13.46.451 4.788.9 8.437 4.535 11.772 7.816 1.883 1.854 4.629-.054 4.179-2.433-.772-4.077-1.107-8.751 3.481-10.407 3.43-1.237 7.561-.727 11.107-.428 2.796.235 3.447-4.479.665-4.911-6.728-1.043-13.303-2.806-20-4l1.103 4.179c3.742-4.593 8.746-7.917 13-12 2.213-2.123-.587-4.971-3.029-3.926-6.354 2.716-12.738 5.415-19.738 5.658l2.411 1.835c-1.889-11.938-2.111-24.059-4-36-.502-3.177-5.32-1.823-4.821 1.33 1.889 11.938 2.111 24.059 4 36 .167 1.055 1.397 1.87 2.411 1.835 7.924-.275 15.065-3.265 22.262-6.342l-3.029-3.926c-4.254 4.082-9.258 7.406-13 12-1.063 1.305-.933 3.815 1.103 4.179 6.698 1.193 13.273 2.956 20 4l.665-4.911c-5.292-.446-11.487-.877-16.028 2.413-4.72 3.418-4.346 9.665-3.383 14.752l4.179-2.433c-3.637-3.578-7.379-6.901-12.246-8.64-5.11-1.825-11.22-1.105-16.522-1.093l1.768 4.268c6.337-5.036 6.965-13.899 6.713-21.393-.306-9.133-2.533-18.072-4.07-27.04-.546-3.171-5.364-1.821-4.824 1.332z"/>
  </g>
  <g class="bodylegs">
    <path fill="#FFF" d="M59.579 146.879l-2.214 10.926-3.311 17.368-2.063 16.456v15.331l3.153 9.506 7.22 9.413 17.959 6.823 17.257 1.642 23.225-4.465 8.774-4.379 9.206-8.091 6.544-13.28-1.582-13.518-7.237-31.732-5.931-14.23-2.25-7.27-65.965 2.523z"/>
    <path d="M131.168 151.544a170.783 170.783 0 018.467 28.491c2.054 10.018 3.462 21.335-.059 31.181-3.291 9.203-14.354 14.618-22.913 17.502-9.606 3.237-20.541 4.325-30.421 1.75-3.123-.813-4.447 4.009-1.329 4.822 19.172 4.997 45.609-1.345 57.225-18.298 5.951-8.684 4.964-21.336 3.529-31.175-1.776-12.185-5.153-24.158-9.679-35.604-1.17-2.961-6.007-1.671-4.82 1.331z"/>
    <path d="M55.829 149.629c-1.267 18.163-8.436 35.659-6.791 54.072 1.884 21.082 19.205 31.294 39.041 32.428 3.223.185 3.205-4.816 0-5-10.21-.583-20.708-3.487-27.713-11.318-5.534-6.186-6.669-15.009-6.578-22.966.184-16.001 5.932-31.315 7.042-47.216.224-3.22-4.778-3.198-5.001 0z"/>
  </g>
  <g class="frontwing">
    <path fill="#FFF" d="M58.579 148.129l-5.04 7.605-7.935 19.923-4.061 18.252-.861 5.72-12.445.82-16.983 3.819-6.675 6.611-2.16 7.5 5.506 4.734 12.343.066-3.181 7.728 1.931 6.972 9.259 2.41 19.748-1.874-3.623 3.407-.926 5.201 6.184 3.01h8.919l14.097-3.444 8.903-8.71 4-8-1.533-24.296-1.198-16.69-.269-7.014-3.731-20.5-13.269-12.25z"/>
    <path d="M53.42 150.617c-10.307 18.887-16.219 40.673-15.341 62.262.131 3.213 5.131 3.226 5 0-.843-20.725 4.76-41.6 14.659-59.738 1.545-2.831-2.773-5.355-4.318-2.524z"/>
    <path d="M35.915 196.468c-12.811 3.567-29.428 1.045-34.943 16.29-5.447 15.058 13.372 13.471 22.607 12.621l-1.768-4.268c-3.53 3.302-8.219 7.848-7.842 13.163.281 3.958 3.783 6.105 7.172 7.275 8.513 2.939 20.762 1.914 29.103-1.26l-1.926-4.569c-3.576 2.255-7.708 4.931-8.053 9.528-.274 3.663 4.016 5.682 6.798 6.67 9.811 3.486 23.024.521 31.074-5.818 8.554-6.736 8.918-18.509 8.892-28.54-.031-11.925-1.861-23.766-1.949-35.682-.024-3.223-5.024-3.226-5 0 .122 16.459 4.112 33.442.911 49.797-1.923 9.824-9.845 13.82-18.941 15.943-4.303 1.004-8.404.829-12.678-.23-1.15-.285-3.588-.891-4.1-2.182-.656-1.655 4.75-4.652 5.57-5.17 2.302-1.451.822-5.615-1.926-4.569-6.571 2.502-14.305 2.851-21.24 2.278-2.514-.207-6.746-.648-8.38-2.913-2.232-3.092 4.275-8.523 6.053-10.187 1.509-1.412.431-4.47-1.768-4.268-4.507.414-9.027.899-13.555.547-6.692-.521-5.146-5.127-2.766-9.81 4.748-9.343 21.407-7.438 29.985-9.826 3.101-.862 1.781-5.686-1.33-4.82z"/>
  </g>
  <g class="head">
    <path fill="#FFF" d="M46.246 112.212l-1.844 7.667v8.667l3.623 11.449 7.554 5.47 4 1.414 70-2 8.722-2 3.278-12.942-2.468-13.447-2.532-9.611-18.498-36.559-20.852 1.679-12.65-4.12-26-3-6.984 21.22z"/>
    <path fill="#FFF" d="M48.579 74.879c-21 22 4 53 27 33s-6-44-26-31"/>
    <path d="M46.812 73.111c-8.764 9.59-12.108 23.999-3.632 34.968 8.313 10.759 22.165 10.759 32.51 2.916 10.42-7.899 14.571-20.955 5.822-31.64-7.974-9.738-22.89-10.953-33.194-4.635-2.743 1.682-.233 6.008 2.523 4.317 8.251-5.059 18.535-4.159 25.729 2.376 9.153 8.315 3.862 19.756-4.557 26.139-7.983 6.053-18.119 5.473-24.666-2.191-7.432-8.702-3.975-21.082 3-28.715 2.17-2.374-1.356-5.92-3.535-3.535z"/>
    <path d="M63.579 96.379c3.224 0 3.224-5 0-5s-3.224 5 0 5z"/>
    <path fill="#FFF" d="M109.579 63.879c-23 12-18 51 11 46 34-6 20-42-6-48"/>
    <path d="M108.317 61.72c-11.734 6.416-17.516 19.604-15.063 32.634 2.568 13.642 15.062 19.92 27.989 17.936 13.402-2.058 23.782-11.701 21.51-25.816-2.163-13.436-14.902-23.884-27.51-27.005-3.13-.775-4.458 4.047-1.329 4.821 10.348 2.562 19.566 9.938 23.155 20.156 4.065 11.573-3.739 19.703-14.537 22.479-11.445 2.944-21.811-2.105-24.457-13.9-2.406-10.726 3.352-21.84 12.765-26.987 2.83-1.547.307-5.865-2.523-4.318z"/>
    <path d="M103.42 92.141c.62 1.269 1.12 3.173 2.391 4.506.94.986 2.589.946 3.535 0 .98-.98.94-2.55 0-3.535a3.935 3.935 0 01-.26-.287c.405.468.064.038-.007-.088a4.713 4.713 0 01-.159-.318c.288.6.006.009-.038-.105-.354-.911-.715-1.818-1.144-2.696-.598-1.224-2.312-1.546-3.42-.897-1.24.725-1.496 2.195-.898 3.42z"/>
    <path fill="#EE1C4E" d="M58.579 64.879l26 3 18 5.311h7.334l8.168-2.87 8.455-7.017 1.688-8.798-10.339-5.056-15.306-.32-6 .75 5.334-6.718 3.666-9.949 6.153-10.781-.481-15.412-8.672-4.14-11.5 3.099-9.5 9.579-6.333 10.988-1.6 11.49-10.067-14.722-7-9.434-4.984 10.333.396 26.333z"/>
    <path d="M54.812 15.646c7.026 8.237 12.28 17.904 16.357 27.897.989 2.424 5.047 2.382 4.911-.665-.441-9.856 3.061-19.666 8.749-27.629 4.458-6.24 16.152-14.448 23.549-7.804 5.221 4.69.771 15.653-1.279 20.797-2.82 7.08-6.946 14.378-12.287 19.868-1.857 1.909.057 4.592 2.432 4.178 4.736-.825 30.392-3.582 29.099 5.454-1.137 7.941-9.963 11.2-16.753 12.009-7.703.917-17.035.171-23.749-4.032-2.741-1.716-5.249 2.611-2.523 4.317 13.079 8.188 45.056 8.529 47.942-11.635 2.192-15.316-26.979-12.392-35.345-10.934l2.432 4.178c6.718-6.903 11.454-16.052 14.699-25.037 2.785-7.711 4.762-18.337-2.736-23.993-8.563-6.459-21.064.28-27.146 6.82-8.034 8.64-12.61 21.705-12.084 33.442l4.911-.665c-4.424-10.844-10.017-21.163-17.643-30.103-2.097-2.455-5.617 1.099-3.536 3.537z" id="waddle"/>
    <path d="M53.42 15.617c-7.424 15.718-4.843 35.441 3 50.523 1.488 2.861 5.803.333 4.317-2.523-6.952-13.368-9.609-31.483-3-45.477 1.369-2.897-2.94-5.437-4.317-2.523z"/>
    <g>
      <path d="M42.079 117.879c-.927 13.011 2.713 27.646 16.835 31.411 3.12.831 4.442-3.992 1.329-4.822-11.895-3.171-13.923-15.941-13.165-26.589.231-3.219-4.771-3.197-4.999 0z"/>
    </g>
    <g>
      <path d="M134.168 107.543c1.919 9.867 13.871 35.24-4.589 34.835-3.226-.07-3.219 4.93 0 5 24.152.529 11.999-27.851 9.411-41.165-.615-3.162-5.433-1.819-4.822 1.33z"/>
    </g>
 
  <g class="bottomjaw">
    <path fill="#FBA919" d="M78.848 119.105l4.035 9.467 9.055 9.61 5.35 1.72 5.547-2.877 3.972-5.427 1.953-5.658-10.817-6.228-16.031-4.402z"/>
    <path d="M77.823 121.003c1.288 11.641 20.565 32.303 28.693 14.543 1.332-2.909-2.977-5.452-4.317-2.523-5.518 12.057-18.572-4.754-19.375-12.02-.351-3.169-5.355-3.203-5.001 0z"/>
    <g>
      <path d="M107.433 134.141c.91-3.094 1.802-6.188 2.382-9.363.575-3.152-4.243-4.499-4.821-1.33-.58 3.175-1.472 6.27-2.382 9.363-.912 3.101 3.913 4.416 4.821 1.33z"/>
    </g>
    <g>
      <path d="M91.766 125.178c1.76 2.874 5.301 4.83 8.613 5.166 4.175.425 6.041-3.161 4.928-6.823-.934-3.077-5.761-1.764-4.821 1.33.113.371.22.696.231 1.077.054-.214.044-.183-.03.093.076-.276.882-.876.343-.558.205-.121.127-.079-.109-.077-.27.002-.547-.066-1.041-.202-1.447-.398-3.164-1.495-3.797-2.529-1.68-2.745-6.006-.235-4.317 2.523z"/>
    </g>
  </g>
  <g id="topjaw">
    <path fill="#FBA919" d="M91.579 124.879l10.434 6.333 10.566 1.957 5.306-1.957.017-5.32-2.636-8.013-4.966-5.745-7.721-2.934-15.5 2.5-7.5 4.179v2z" id="Layer_9"/>
    <path d="M80.841 118.038c10.823-7.617 28.604-10.909 33.742 4.771 4.96 15.141-16.203 4.632-21.236.303-2.431-2.091-5.984 1.43-3.535 3.535 6.892 5.928 32.407 17.548 30.442-1.167-.971-9.247-7.295-16.882-16.48-18.612-8.779-1.654-18.346 1.849-25.456 6.853-2.615 1.839-.121 6.177 2.523 4.317z"/>
  </g>
  </g>
</svg>
</div>

</template>


<script>
export default {
  name: 'Chicken',
  components: {
    
  },
  methods: {
    playSound () {
      const x = document.getElementById("sound");
      x.volume = 0.6;
      x.play();
      
      this.$parent.$emit('chicken-click')
    }
  },
   data() {
    return { 
      clickCount: 0,
    }
  },
  created() {
    // Emited by Chicken component on click.
    this.$on('chicken-click', () => {
      this.clickCount++
    })
  },
}
</script>

<style>
.chikin {
  margin-top: 2em;
  user-select: none;
}
.hi {
  max-width: 20em;
}
.backwing {
  animation-name: wingwave;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.bodylegs {
  animation-name: body-down;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.frontwing {
   animation-name: wing-back;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
 .head:hover .bottomjaw {
    animation-name: jaw-open;
  animation-duration: 1s;
  
}
 .chikin:active .bottomjaw {
    animation-name: jaw-open;
  animation-duration: .2s;
  
}
.head {
    animation-name: head-move;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes wingwave {
0% {
  transform: rotateZ(0deg) ;
}
25% {
  transform: rotateZ(-5deg) translateX(-20px);
}
50% {
  transform: rotateZ(0deg) ;
}
75% {
  transform: rotateZ(-5deg) translateX(-20px);
}
100% {
  transform: rotateZ(0deg);
}
}
@keyframes body-down {
  0% {
  transform: rotateY(0deg) translateY(0px);
  }
  50% {
  transform: rotateY(-2deg) translateY(2px)
  }
  100% {
 transform: rotateY(0deg) translateY(0px);
  }
}
@keyframes wing-back {
  0% {
  transform: rotateY(0deg) translateY(0px);
  }
  50% {
  transform: rotateZ(-1deg) translateX(-2px)
  }
  100% {
 transform: rotateY(0deg) translateY(0px);
  }
}
@keyframes jaw-open {
  0% {
  transform: rotateY(0deg) translateX(0px);
  }
  50% {
  transform: rotateZ(3deg) translateY(-1px) translateX(5px);
  }
  100% {
 transform: rotateY(0deg) translateX(0px);
  }
}
@keyframes head-move {
  0% {
  transform: rotateY(0deg) translateY(0px);
  }
  50% {
  transform: rotateZ(1deg) translateX(2px)
  }
  100% {
 transform: rotateY(0deg) translateY(0px);
  }
}
@keyframes jaw-move {
  0% {
   transform: rotateY(0deg) translateY(0px);
  }
  50% {
   transform: rotateY(0deg) translateY(0px);
  }
  100% {
  transform: rotateZ(-2deg) translateX(-2px)
  
}
}
</style>