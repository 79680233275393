<template>
  <div>
    <v-container>
        <h1 class="text-center">Characters!</h1>
        <h2 class="text-center">Our friends</h2>
        <div class="d-flex flex-row">
              <v-card
                class="mx-auto mt-10 pa-4 d-flex flex-column flex-sm-row"
                max-width="700">
                 <img src="../assets/stamp.png" max-height="auto" max-width="300" width="200" class="mx-auto">
               <v-card-text>
                  <p class="display-1 text--primary">Recettes des Belettes </p>
                  <p class="">Dad and Ben Belette are the most chic weasels probably in the whole world.  They love to go on adventures, find the prettiest bijoux for their collections, and discover food and history along the way.</p>
                </v-card-text>
              </v-card>
        </div>
        <div class="d-flex flex-row">
              <v-card
                class="mx-auto mt-10 pa-4 d-flex flex-column flex-sm-row"
                max-width="700">
                <v-card-text>
                  <p class="display-1 text--primary">Rita</p>
                  <p class="">Dad Belette's Jack Russel Rita is just as jetset and chic as he is.  She's a curious, bold baby with a heart of gold. Always ready for adventures as well, you'll find her in the garden, at the market, and anywhere her dads go too.</p>
                </v-card-text>
                 <img src="../assets/aumarche-coloure.png" height="auto" max-width="300" width="300" class="mx-auto">
              </v-card>
        </div>
        <div class="d-flex flex-row">
              <v-card
                class="mx-auto mt-10 pa-4 d-flex flex-column flex-sm-row"
                max-width="700">
                 <img src="../assets/chickensplat.png" max-height="auto" max-width="300" width="200" class="mx-auto">
               <v-card-text>
                  <p class="display-1 text--primary">Bock</p>
                  <p class="">The coolest chicken in the galaxy.  From a small farming planet on the outer parts of the solar system, Bock rose up from rags to riches as one of the best hoverboarders the verse has seen.</p>
                </v-card-text>
              </v-card>
        </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Characters',
  title: 'Cosmic Industries - Characters',
  components: {
  },
};
</script>

<style>
.main {
  color: #3949AB;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 800;
}
h1,h2 {
    text-align: center;
}
</style>
