<template>
  <div>
    <v-container>
        <h1 class="text-center">Welcome to the Playground!</h1>
        <h2 class="text-center">Home to our previous animations.</h2>
        <p class="text-center">(Go poke the chicken...you know you want to.)</p>
        <Chicken class="text-center mx-auto mt-10 pt-10"></Chicken>
        <v-card
          v-if="clickCount > 99"
          class="text-center mx-auto"
          max-width="300px"
          flat
        >
          <div class="text-h4 pt-2">{{clickCount}} Clucks!</div>
        </v-card>
      <Train/>
      
    </v-container>
  </div>
</template>

<script>
import Train from '../components/Train.vue';
import Chicken from '../components/Chicken.vue';
export default {
  name: 'Playground',
  title: 'Cosmic Industries - Playground',
  components: {
    Train,
    Chicken,
  },

  data() {
    return { 
      clickCount: 0,
    }
  },
  created() {
    // Emited by Chicken component on click.
    this.$on('chicken-click', () => {
      this.clickCount++
    })
  },
  computed: {
    featuredProducts: function () {
      let prodList = this.$getParent('App').products.filter(x => x.fields["Featured"] === true);
      return prodList
    }
  }
};
</script>

<style>
.main {
  color: #3949AB;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 800;
}
</style>
